import React from 'react';
import s from './RadioOption.scss';
import cx from 'classnames';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface FilterOptionProps extends IGalleryGlobalProps {
  id: string;
  label: string;
  onChange: Function;
  selected: boolean;
}

export class RadioOption extends React.PureComponent<FilterOptionProps & IProvidedTranslationProps> {
  public render() {
    const {id, label, onChange, selected} = this.props;
    const classNames = cx('wixSdkShowFocusOnSibling', s.label, {[s.selected]: selected});

    return (
      <label data-hook="label" className={classNames}>
        <input
          data-hook="radio-option-input"
          type="radio"
          value={id}
          checked={selected}
          onChange={e => onChange(e)}
          className={s.input}
        />
        {label}
      </label>
    );
  }
}
